import { ChangeDetectorRef, Component, effect, inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

import { DspStorage } from '@lib/dsp-storage.class';
import { UserService } from '@services/user/user.service';

import { PageMetasActions } from '@store/metas';

import { IconComponent } from '@core/components/common/icon/icon.component';
import { ReactiveComponent } from '@core/components/_base/reactive.component';

import { LOCALSTORAGE_DARKMODE_KEY } from '@lib/constants';
import { IS_SERVER } from '@lib/tokens';

@Component({
  standalone: true,
  selector: 'dsp-theme-toggle',
  templateUrl: './theme-toggle.component.html',
  styleUrls: ['./theme-toggle.component.scss'],
  imports: [IconComponent],
})
export class ThemeToggleComponent extends ReactiveComponent {
  public darkmodeEnabled = false;
  private isServer: boolean = inject(IS_SERVER);
  private document: Document = inject(DOCUMENT);
  private userService = inject(UserService);
  private cd = inject(ChangeDetectorRef);

  constructor() {
    super();
    effect(() => {
      this.darkmodeEnabled = this.userService.$user()?.darkmodeEnabled ?? true;

      if (!this.darkmodeEnabled) {
        this.injectStylesheet();
      }

      this.applyCssClass();
      this.setLocalStorageEntry();
    });
  }

  public toggle(): void {
    this.darkmodeEnabled = !this.darkmodeEnabled;

    if (!this.darkmodeEnabled) {
      this.injectStylesheet();
    }

    this.applyCssClass();
    this.updateUser();
    this.setLocalStorageEntry();

    this.store.dispatch(PageMetasActions.toggleTheme({ dark: this.darkmodeEnabled }));
  }

  private updateUser(): void {
    if (!this.userService.$user()) {
      return;
    }

    const metas = { ...this.userService.$user().metas, darkmode: this.darkmodeEnabled };
    this.userService.update({ id: this.userService.$user().id, metas });
  }

  private injectStylesheet(): void {
    if (document.getElementById('styles-light-mode')) {
      return;
    }

    const link = document.createElement('link');
    link.id = 'styles-light-mode';
    link.rel = 'stylesheet';
    link.type = 'text/css';
    link.href = '/light-theme.css';

    document.body.appendChild(link);
  }

  private applyCssClass(): void {
    const html = this.document?.documentElement;

    if (html) {
      const theme = this.darkmodeEnabled ? 'dark' : 'light';
      html.setAttribute('data-theme', theme);
    }

    this.cd.markForCheck();
  }

  private setLocalStorageEntry(): void {
    if (this.isServer) {
      return;
    }

    DspStorage.setItem(LOCALSTORAGE_DARKMODE_KEY, this.darkmodeEnabled ? '1' : '0');
  }
}
