import { APP_ID, APP_INITIALIZER, ApplicationConfig, ErrorHandler } from '@angular/core';
import { APP_BASE_HREF, IMAGE_LOADER, ImageLoaderConfig } from '@angular/common';
import {
  provideRouter,
  Router,
  withComponentInputBinding,
  withInMemoryScrolling,
  withRouterConfig,
  withViewTransitions,
} from '@angular/router';
import { provideClientHydration, withHttpTransferCacheOptions } from '@angular/platform-browser';
import { HttpRequest, provideHttpClient, withFetch, withInterceptors } from '@angular/common/http';

import { APP_ROUTES } from './app.routes';
import { provideStore } from '@ngrx/store';
import { provideEffects } from '@ngrx/effects';
import { MinimalRouterStateSerializer, provideRouterStore } from '@ngrx/router-store';

import { EnvironmentService } from '@services/environment/environment.service';
import { UserService } from '@services/user/user.service';
import { UserTrackingService } from '@services/user-tracking/user-tracking.service';

import { reducers, rootEffects, metaReducers } from './store';

import { initApp } from '@lib/init-app.browser';

import { CanonicalUrlService } from '@services/canonical-url/canonical-url.service';
import { dspInterceptor } from '@services/dsp-interceptor/dsp-interceptor';
import { GlobalErrorHandler } from '@services/global-error-handler';
import { TraceService } from '@sentry/angular';

import { BUILDVERSION, IS_SERVER, WINDOW, WINDOW_ID } from '@lib/tokens';
import { environment } from '../environments/environment';

import buildVersion from '../public/version.json';

export const appConfig: ApplicationConfig = {
  providers: [
    provideHttpClient(withFetch(), withInterceptors([dspInterceptor])),
    provideRouter(
      APP_ROUTES,
      withInMemoryScrolling({ scrollPositionRestoration: 'top' }),
      withRouterConfig({ onSameUrlNavigation: 'reload', paramsInheritanceStrategy: 'always' }),
      withViewTransitions(),
      withComponentInputBinding()
    ),
    provideClientHydration(
      withHttpTransferCacheOptions({
        filter: (req: HttpRequest<unknown>) => !req.url?.toLowerCase().includes('public/user'),
        includeHeaders: ['etag'],
      })
    ),
    provideStore(reducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: false,
        strictActionImmutability: false,
      },
    }),
    provideRouterStore({ serializer: MinimalRouterStateSerializer }),
    provideEffects(rootEffects),
    { provide: IS_SERVER, useValue: false },
    { provide: APP_BASE_HREF, useValue: environment.host },
    { provide: APP_ID, useValue: 'DSP' },
    { provide: WINDOW, useValue: typeof window === 'undefined' ? {} : window },
    { provide: WINDOW_ID, useValue: `B-${Date.now()}-${Math.floor(Math.random() * 10000)}` },
    { provide: BUILDVERSION, useValue: buildVersion },
    {
      provide: IMAGE_LOADER,
      useValue: (config: ImageLoaderConfig) => {
        const base = `/assets/images/responsive/${config.src}/${config.src}`;
        return config.width ? `${base}-${config.width}w.webp` : `${base}.webp`;
      },
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initApp,
      deps: [UserService, UserTrackingService, EnvironmentService, CanonicalUrlService, GlobalErrorHandler],
      multi: true,
    },
    { provide: TraceService, deps: [Router] },
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
  ],
};
