<nav class="main-nav">
  <dsp-icon [icon]="$subNavVisible() ? 'close' : 'menu'" class="hover" (click)="toggleSubnav()"/>

  <a title="home" id="home-link" routerLink="/">
    <dsp-logo/>
  </a>

  <dsp-icon class="dynamic bubble random-group" icon="shuffle" title="Random Group" (click)="randomGroup()"/>

  <div class="input-wrapper bubble dynamic">
    <input #needle type="text" alt="Search" aria-label="Search for groups"
           (keyup)="search($event.key)" (focus)="needle.value = ''"/>
    <dsp-icon class="search" icon="search"/>
  </div>

  <dsp-theme-toggle class="dynamic"/>
</nav>


@defer (when $subNavVisible() || $query()) {
  <dsp-sub-nav [skeletons]="skeletons" [query]="$query()" (emitter)="processEmission($event)" [class.visible]="$subNavVisible()"/>
} @placeholder {
  <div></div>
}

<dsp-json-ld [jsonLd]="jsonLd"/>
