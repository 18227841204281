import { bootstrapApplication } from '@angular/platform-browser';
import { AppComponent } from './app/app.component';
import { DspStorage } from '@lib/dsp-storage.class';

import { init as initSentry, browserTracingIntegration } from '@sentry/angular';

import { appConfig } from './app/app.config';

import { LOCALSTORAGE_DARKMODE_KEY } from '@lib/constants';
import { environment } from './environments/environment';
import buildVersion from './public/version.json';

const checkForDarkMode: () => void = () => {
  let darkModeDisabled = false;
  const html = document?.querySelector('html');

  if (!(html && window)) {
    return;
  }

  darkModeDisabled = DspStorage.getItem(LOCALSTORAGE_DARKMODE_KEY) === '0';

  if (darkModeDisabled) {
    html.classList?.remove('dark');
  }
};

if (environment.key === 'PROD_LIVE') {
  initSentry({
    environment: environment.key,
    release: buildVersion.version,
    dsn: 'https://b471788c0a3ef47d78542247dd5b7e70@o4506728588312576.ingest.sentry.io/4506728593227776',
    integrations: [browserTracingIntegration()],
    // Performance Monitoring
    tracesSampleRate: 1, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [/^https:\/\/www\.docsnyderspage\.com/],
    // Session Replay
    replaysSessionSampleRate: 0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

document.addEventListener('DOMContentLoaded', () => {
  checkForDarkMode();
  bootstrapApplication(AppComponent, appConfig).catch(err => console.error(err));
});
