import { EnvironmentService, UserService } from '../app/services';
import { UserTrackingService } from '@services/user-tracking/user-tracking.service';
import { GlobalErrorHandler } from '@services/global-error-handler';
import { CanonicalUrlService } from '@services/canonical-url/canonical-url.service';

export const initApp =
  (
    userService: UserService,
    trackingService: UserTrackingService,
    environmentService: EnvironmentService,
    canonicalUrlService: CanonicalUrlService,
    errorHandler: GlobalErrorHandler
  ) =>
  async () => {
    canonicalUrlService.init();

    return userService
      .getCurrentUser()
      .then(() => environmentService.init())
      .then(() => trackingService.init())
      .catch((e: Error) => {
        errorHandler.handleError(e, 'Error initializing Browser App');
      })
      .finally(() => true);
  };
