import { Component, inject, OnInit, Signal } from '@angular/core';
import { RouterOutlet } from '@angular/router';

import { PageMetasService } from '@services/page-metas/page-metas.service';
import { TransferStateManager } from '@services/transfer-state/transfer-state-manager.service';

import { CrackergroupActions, CrackergroupSelectors } from '@store/crackergroup';
import { PageMetasActions } from '@store/metas';

import { ICrackergroupSkeleton } from '@interfaces';

import { FooterComponent } from '@core/components/footer/footer.component';
import { FormContainerComponent } from '@core/components/forms/form-container/form-container.component';
import { LatestIntroBoxComponent } from '@core/components/intro/latest-intro-box/latest-intro-box.component';
import { LoadingIndicatorComponent } from '@core/components/common/loading-indicator/loading-indicator.component';
import { MainNavComponent } from '@core/components/navs/main-nav/main-nav.component';
import { NotificationsComponent } from '@core/components/notifications/notifications.component';
import { PlayboxContainerComponent } from '@core/components/playbox/playbox-container/playbox-container.component';
import { ReactiveComponent } from '@core/components/_base/reactive.component';
import { ScrollTopComponent } from '@core/components/common/scroll-top/scroll-top.component';

@Component({
  standalone: true,
  selector: 'dsp-root',
  imports: [
    RouterOutlet,
    MainNavComponent,
    FooterComponent,
    PlayboxContainerComponent,
    ScrollTopComponent,
    LoadingIndicatorComponent,
    LatestIntroBoxComponent,
    NotificationsComponent,
    FormContainerComponent,
  ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent extends ReactiveComponent implements OnInit {
  public $groupsSkeletons: Signal<ICrackergroupSkeleton[]>;
  private transferStateManager = inject(TransferStateManager);
  private metasService = inject(PageMetasService);

  constructor() {
    super();
    this.transferStateManager.init();
    this.$groupsSkeletons = this.store.selectSignal(CrackergroupSelectors.selectSkeletons);
  }

  ngOnInit(): void {
    this.metasService.init();

    if (!this.transferStateManager.hasCollection('GROUPS')) {
      this.store.dispatch(CrackergroupActions.fetchSkeletons());
    }

    if (!this.transferStateManager.hasCollection('METAS')) {
      this.store.dispatch(PageMetasActions.getDefaults());
    }
  }
}
