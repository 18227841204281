import {
  afterNextRender,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  inject,
  Input,
  OnChanges,
  OnInit,
  signal,
  SimpleChanges,
  ViewChild,
  WritableSignal,
} from '@angular/core';
import { NavigationEnd, Router, RouterLink } from '@angular/router';
import { DOCUMENT, ViewportScroller } from '@angular/common';

import { SeoService } from '@services/seo/seo.service';

import { filter } from 'rxjs/operators';

import { UserActions } from '@store/user';
import { IntroActions } from '@store/intro';
import { RouterActions } from '@store/router';

import { SubnavAction, ICrackergroupSkeleton } from '@interfaces';
import { TJsonLd } from '@models/interfaces/seo';

import { IconComponent } from '@core/components/common/icon/icon.component';
import { JsonLdComponent } from '@core/components/common/json-ld/json-ld.component';
import { LogoComponent } from '@core/components/common/logo/logo.component';
import { ReactiveComponent } from '@core/components/_base/reactive.component';
import { SubNavComponent } from '../sub-nav/sub-nav.component';
import { ThemeToggleComponent } from '@core/components/common/theme-toggle/theme-toggle.component';

import { IS_SERVER } from '@lib/tokens';
import { debounce, sample } from 'lodash-es';

@Component({
  standalone: true,
  selector: 'dsp-main-nav',
  templateUrl: './main-nav.component.html',
  styleUrls: ['./main-nav.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [IconComponent, LogoComponent, JsonLdComponent, SubNavComponent, ThemeToggleComponent, RouterLink],
})
export class MainNavComponent extends ReactiveComponent implements OnInit, OnChanges {
  @Input() skeletons: ICrackergroupSkeleton[];
  @ViewChild('needle') private needleRef: ElementRef<HTMLInputElement>;

  public jsonLd: TJsonLd;
  public active = false;
  public $subNavVisible: WritableSignal<boolean> = signal(false);
  public $query: WritableSignal<string> = signal('');

  private boundEscapeKeyListenerFn: (ev: Event) => void;
  private resizeObserver: ResizeObserver;

  private isServer = inject(IS_SERVER);
  private document = inject(DOCUMENT);
  private router = inject(Router);
  private scroller = inject(ViewportScroller);
  private el = inject(ElementRef);

  constructor() {
    super();

    afterNextRender(() => {
      this.subscribeToNavigation();
      this.initResizeObserver();
      this.initScrollListener();
    });
  }

  ngOnInit(): void {
    this.boundEscapeKeyListenerFn = this.escapeKeyListenerFn.bind(this);
    this.updateCssClasses();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.skeletons?.currentValue) {
      this.jsonLd = SeoService.buildGroupsJsonLd(changes.skeletons.currentValue);
    }
  }

  public processEmission(emission: { action: SubnavAction; payload?: Record<string, string> }): void {
    const { action, payload } = emission;

    switch (action) {
      case 'CLOSE':
        this.close();
        break;
      case 'NAVIGATE':
        this.navigate(payload.path, payload.fragment);
        this.close();
        break;
      case 'RANDOM':
        this.close();
        if (emission.payload.entity === 'groups') {
          const randomGroup = sample(this.skeletons);
          this.navigate(randomGroup.path);
        } else {
          this.store.dispatch(IntroActions.playRandom());
        }
        break;
    }
  }

  public toggleSubnav(): void {
    const next = !this.$subNavVisible();
    this.$subNavVisible.set(next);

    if (next) {
      this.bindEscapeKey(next);
      this.updateCssClasses();
    } else {
      this.close();
    }

    this.store.dispatch(UserActions.setActivity({ kind: next ? 'opened nav' : 'closed nav' }));
  }

  public close(): void {
    this.needleRef.nativeElement.value = '';
    this.active = false;
    this.document.body.classList.remove('no-scroll');
    this.bindEscapeKey(false);
    this.$subNavVisible.set(false);
    this.updateCssClasses();
  }

  public randomGroup(): void {
    const group = sample(this.skeletons);
    this.navigate(group.path);
  }

  public navigate(url: string, fragment: string = null): void {
    this.close();

    this.store.dispatch(
      RouterActions.navigate({ url, state: { fragment, extras: { tracking: { origin: 'mainNav' } } } })
    );
  }

  public search(key: string): void {
    if (key === 'Escape') {
      this.close();
      return;
    }

    this.$subNavVisible.set(true);
    this.$query.set(this.needleRef?.nativeElement?.value ?? '');
  }

  private subscribeToNavigation(): void {
    this.router.events.pipe(filter(ev => ev instanceof NavigationEnd)).subscribe(() => {
      this.close();
      this.el.nativeElement.blur();
    });
  }

  private initScrollListener(): void {
    window.addEventListener(
      'scroll',
      debounce(() => {
        this.el.nativeElement.classList.toggle('scrolled', window.scrollY > 0.4 * window.innerHeight);
        // Trigger for ScrollTopComponent
        document.body.classList.toggle('scroll-top', window.scrollY > window.innerHeight);
      }, 150)
    );
  }

  private initResizeObserver(): void {
    this.resizeObserver = new ResizeObserver(entries => {
      const headerHeight = entries[0].borderBoxSize?.[0]?.blockSize ?? 0;
      this.document.body.style.setProperty('--dsp-nav-height', headerHeight + 'px');
      this.scroller.setOffset([0, headerHeight]);
    });

    this.resizeObserver.observe(this.el.nativeElement.querySelector('nav'));
  }

  private updateCssClasses(): void {
    this.el.nativeElement?.classList.toggle('active', this.active);
    this.document.body.classList.toggle('no-scroll', this.active);
  }

  private bindEscapeKey(bind = true) {
    if (!this.isServer) {
      return;
    }

    const method = bind ? 'addEventListener' : 'removeEventListener';
    this.document[method]('keyup', this.boundEscapeKeyListenerFn);
  }

  private escapeKeyListenerFn(ev: Event): void {
    if ((ev as KeyboardEvent)?.key === 'Escape') {
      this.close();
    }
  }
}
