import { Routes } from '@angular/router';

export const APP_ROUTES: Routes = [
  {
    path: 'groups',
    data: { customSeo: true },
    loadChildren: () => import('./features/crackergroups/crackergroups-routes'),
  },
  {
    path: 'sceners',
    data: { customSeo: true },
    loadChildren: () => import('./features/sceners/sceners-routes'),
  },
  {
    path: 'news',
    loadChildren: () => import('./features/news/news-routes'),
  },
  {
    path: 'timeline',
    loadChildren: () => import('./features/timeline/timeline-routes'),
  },
  {
    path: '404',
    loadComponent: () =>
      import('./core/components/not-found/not-found.component').then(m => m.NotFoundComponent),
  },
  {
    path: '',
    pathMatch: 'full',
    loadComponent: () =>
      import('./features/welcome/components/welcome-page/welcome-page.component').then(
        m => m.WelcomePageComponent
      ),
  },
  {
    path: '**',
    redirectTo: '/404',
  },
];
