import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  computed,
  ElementRef,
  inject,
  input,
  Signal,
} from '@angular/core';
import { NgClass } from '@angular/common';
import { RouterLink } from '@angular/router';

import { UserActions } from '@store/user';

import { ICrackergroupSkeleton } from '@interfaces';
import { TJsonLd } from '@models/interfaces/seo';

import { JsonLdComponent } from '@core/components/common/json-ld/json-ld.component';
import { ReactiveComponent } from '@core/components/_base/reactive.component';

import { BUILDVERSION } from '@lib/tokens';

type CrackergroupCollectionItem = { label: string; items: ICrackergroupSkeleton[] };

@Component({
  standalone: true,
  selector: 'dsp-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgClass, RouterLink, JsonLdComponent],
})
export class FooterComponent extends ReactiveComponent {
  public $groups = input<ICrackergroupSkeleton[]>([], { alias: 'groups' });

  public $jsonLd: Signal<TJsonLd> = computed(() => this.buildJsonLd(this.$groups()));
  public $collection = computed(() => this.buildCollection(this.$groups()));
  public visible = false;
  public buildInfo: string;

  private highlightedLetter: string = null;
  private buildVersion = inject(BUILDVERSION);
  private el = inject(ElementRef);
  private cd = inject(ChangeDetectorRef);

  constructor() {
    super();
    this.buildBuildInfo();
  }

  public showDialog(subject: string): void {
    this.store.dispatch(UserActions.showContactForm({ subject }));
  }

  private buildBuildInfo(): void {
    const { timestamp, version } = this.buildVersion;
    const date = new Intl.DateTimeFormat('en-US').format(new Date(timestamp));
    this.buildInfo = `v${version}, ${date}`;
    this.cd.markForCheck();
  }

  public setHighlightLetter(letter: string): void {
    if (letter === this.highlightedLetter) {
      return;
    }

    this.highlightedLetter = letter;
    this.el.nativeElement.setAttribute('data-letter', letter);
    this.cd.markForCheck();
  }

  private buildCollection(groups: ICrackergroupSkeleton[] = []): CrackergroupCollectionItem[] {
    return (groups || []).reduce((p, c) => {
      let container = p.find(pack => pack.label === c.letter);

      if (!container) {
        container = { label: c.letter, items: [c] };
        return [...p, container];
      }

      container.items.push(c);
      return p;
    }, [] as CrackergroupCollectionItem[]);
  }

  private buildJsonLd(groups: ICrackergroupSkeleton[] = []): TJsonLd {
    if (!groups?.length) {
      return null;
    }

    return {
      '@context': 'https://schema.org',
      '@type': ['SiteNavigationElement', 'ItemList'],
      itemListElement: groups.map((item, idx) => ({
        '@type': 'WebPage',
        position: idx,
        name: item.handle ? `${item.name} [${item.handle}]` : item.name,
        url: `https://www.docsnyderspage.com${item.path}`,
      })),
    };
  }
}
