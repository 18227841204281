import { HttpErrorResponse, HttpEvent, HttpHandlerFn, HttpInterceptorFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';

import { EMPTY, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { UserService } from '@services/user/user.service';
import { REQUEST, USER_ID, REAL_IP, IS_SERVER } from '@lib/tokens';

export const dspInterceptor: HttpInterceptorFn = (
  req: HttpRequest<unknown>,
  next: HttpHandlerFn
): Observable<HttpEvent<unknown>> => {
  const isServer = inject(IS_SERVER, { optional: true });
  const userService = inject(UserService);
  const request = inject(REQUEST, { optional: true });
  const realIp = inject(REAL_IP, { optional: true });
  const userId = inject(USER_ID, { optional: true }) || userService.userId || request?.headers?.['X-DSPSRID'];

  let headers = req.headers;
  let params = req.params;

  if (userId) {
    headers = headers.append('X-DSPSRID', userId.toString());
  }

  if (isServer) {
    params = params.appendAll({ server: '1', real_ip: realIp ?? null });
  }

  const cloned = req.clone({ headers, params });

  return next(cloned).pipe(
    catchError((error: HttpErrorResponse | Error) => {
      if (error instanceof HttpErrorResponse && error.status === 204) {
        return EMPTY;
      } else if (error instanceof Error) {
        try {
          if (request) {
            const serialized = JSON.stringify(error);
            console.log(serialized);
          }
        } catch (e) {
          console.log('Caught in Interceptor : ', e);
        }
      }

      return throwError(() => error);
    })
  );
};
